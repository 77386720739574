<template>
  <component :is="tag" ref="observerEl">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
  rootMargin: {
    type: String,
    default: '0px'
  },
  threshold: {
    type: Number,
    default: 0
  },
  tag: {
    type: String,
    default: 'div'
  },
  once: Boolean
})

const emit = defineEmits(['observe:visible', 'observe:invisible'])

const observerEl = ref<HTMLDivElement>()

const callBack = (entries: IntersectionObserverEntry[]) => {
  const entry = entries[0]

  if (entry.isIntersecting) {
    emit('observe:visible')

    if (props.once) {
      observer?.disconnect()
      observer = undefined
    }
  } else {
    emit('observe:invisible')
  }
}

let observer: IntersectionObserver | undefined

onMounted(() => {
  if (window && 'IntersectionObserver' in window) {
    observer = new IntersectionObserver(callBack, {
      rootMargin: props.rootMargin,
      threshold: props.threshold
    })
    if (observerEl.value) {
      observer.observe(observerEl.value)
    }
  } else {
    emit('observe:visible')
  }
})

onBeforeUnmount(() => {
  observer?.disconnect()
  observer = undefined
})
</script>
